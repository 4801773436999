import { produce } from "immer";
import UtilService from "../../service/UtilService";
import Multiselect from "../Multiselect";
import Constant from "../../constant/BackendConstant";

const Component = ({ filterConfig, onChange }) => {
  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Filter</th>
            <th>Value</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Date-time range [min - max UTC]</td>
            <td>
              <input
                type="datetime-local"
                max={filterConfig.trnDateMax}
                value={filterConfig.trnDateMin}
                onChange={(e) =>
                  onChange(
                    produce(filterConfig, (d) => {
                      d.trnDateMin = e.target.value;
                    }),
                  )
                }
              />
            </td>
            <td>
              <input
                type="datetime-local"
                min={filterConfig.trnDateMin}
                value={filterConfig.trnDateMax}
                onChange={(e) =>
                  onChange(
                    produce(filterConfig, (d) => {
                      d.trnDateMax = e.target.value;
                    }),
                  )
                }
              />
            </td>
          </tr>
          <tr>
            <td>Total risk [min - max]</td>
            <td>
              <input
                type="number"
                max={filterConfig.totalRiskMax}
                value={filterConfig.totalRiskMin}
                onChange={(e) =>
                  onChange(
                    produce(filterConfig, (d) => {
                      d.totalRiskMin = e.target.value;
                    }),
                  )
                }
              />
            </td>
            <td>
              <input
                type="number"
                min={filterConfig.totalRiskMin}
                value={filterConfig.totalRiskMax}
                onChange={(e) =>
                  onChange(
                    produce(filterConfig, (d) => {
                      d.totalRiskMax = e.target.value;
                    }),
                  )
                }
              />
            </td>
          </tr>
          <tr>
            <td>Transaction types</td>
            <td colSpan={2}>
              <input
                type="text"
                value={UtilService.arrayToCsv(filterConfig.transactionTypes)}
                onChange={(e) =>
                  onChange(
                    produce(filterConfig, (d) => {
                      d.transactionTypes = UtilService.csvToStringArray(
                        e.target.value,
                      );
                    }),
                  )
                }
              />
            </td>
          </tr>
          <tr>
            <td>Pans</td>
            <td colSpan={2}>
              <input
                type="text"
                value={UtilService.arrayToCsv(filterConfig.pans)}
                onChange={(e) =>
                  onChange(
                    produce(filterConfig, (d) => {
                      d.pans = UtilService.csvToStringArray(e.target.value);
                    }),
                  )
                }
              />
            </td>
          </tr>
          <tr>
            <td>Merchant codes</td>
            <td colSpan={2}>
              <input
                type="text"
                value={UtilService.arrayToCsv(filterConfig.merchantCodes)}
                onChange={(e) =>
                  onChange(
                    produce(filterConfig, (d) => {
                      d.merchantCodes = UtilService.csvToStringArray(
                        e.target.value,
                      );
                    }),
                  )
                }
              />
            </td>
          </tr>
          <tr>
            <td>Reject reason codes</td>
            <td colSpan={2}>
              <input
                type="text"
                value={UtilService.arrayToCsv(filterConfig.rejectReasonCodes)}
                onChange={(e) =>
                  onChange(
                    produce(filterConfig, (d) => {
                      d.rejectReasonCodes = UtilService.csvToStringArray(
                        e.target.value,
                      );
                    }),
                  )
                }
              />
            </td>
          </tr>
          <tr>
            <td>Statuses</td>
            <td colSpan={2}>
              <Multiselect
                values={Constant.transactionStatuses}
                selectedValues={filterConfig.statuses}
                onChange={(e) =>
                  onChange(
                    produce(filterConfig, (d) => {
                      d.statuses = e;
                    }),
                  )
                }
              ></Multiselect>
            </td>
          </tr>
          <tr>
            <td>Detail statuses</td>
            <td colSpan={2}>
              <Multiselect
                values={Constant.transactionDetailStatuses}
                selectedValues={filterConfig.detailStatuses}
                onChange={(e) =>
                  onChange(
                    produce(filterConfig, (d) => {
                      d.detailStatuses = e;
                    }),
                  )
                }
              ></Multiselect>
            </td>
          </tr>
          <tr>
            <td>Risk evaluation statuses</td>
            <td colSpan={2}>
              <Multiselect
                values={Constant.riskEvaluationStatuses}
                selectedValues={filterConfig.riskEvaluationStatuses}
                onChange={(e) =>
                  onChange(
                    produce(filterConfig, (d) => {
                      d.riskEvaluationStatuses = e;
                    }),
                  )
                }
              ></Multiselect>
            </td>
          </tr>
          <tr>
            <td>Transaction ids</td>
            <td colSpan={2}>
              <input
                type="text"
                value={UtilService.arrayToCsv(filterConfig.transactionIds)}
                onChange={(e) =>
                  onChange(
                    produce(filterConfig, (d) => {
                      d.transactionIds = UtilService.csvToNumberArray(
                        e.target.value,
                      );
                    }),
                  )
                }
              />
            </td>
          </tr>
          <tr>
              <td>Estimated frauds only</td>
              <td colSpan={2}>
                  <input
                      type="checkbox"
                      checked={filterConfig.isEstimatedFraud}
                      onChange={(e) =>
                          onChange(
                              produce(filterConfig, (d) => {
                                  d.isEstimatedFraud = !d.isEstimatedFraud;
                              }),
                          )
                      }
                  />
              </td>
          </tr>
          <tr>
            <td>Confirmed frauds only</td>
            <td colSpan={2}>
              <input
                type="checkbox"
                checked={filterConfig.isFraud}
                onChange={(e) =>
                  onChange(
                    produce(filterConfig, (d) => {
                      d.isFraud = !d.isFraud;
                    }),
                  )
                }
              />
            </td>
          </tr>
          <tr>
              <td>Hotspots only</td>
              <td colSpan={2}>
                  <input
                      type="checkbox"
                      checked={filterConfig.isHotspot}
                      onChange={(e) =>
                          onChange(
                              produce(filterConfig, (d) => {
                                  d.isHotspot = !d.isHotspot;
                              }),
                          )
                      }
                  />
              </td>
          </tr>
          <tr>
            <td>Page size</td>
            <td colSpan={2}>
              <input
                type="number"
                min={0}
                step={10}
                value={filterConfig.pageSize}
                onChange={(e) => {
                  onChange(
                    produce(filterConfig, (d) => {
                      d.pageSize = e.target.value < 200 ? e.target.value : 200;
                    }),
                  );
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Page number</td>
            <td colSpan={2}>
              <input
                type="number"
                min={1}
                value={filterConfig.pageNumber}
                onChange={(e) =>
                  onChange(
                    produce(filterConfig, (d) => {
                      d.pageNumber = Number.parseInt(e.target.value);
                      d.pageNumber = d.pageNumber > 500 ? 500 : d.pageNumber;
                      d.pageNumber = d.pageNumber < 0 ? 0 : d.pageNumber;
                    }),
                  )
                }
              />
            </td>
          </tr>
          <tr>
            <td>Refresh [s]</td>
            <td colSpan={2}>
              <input
                type="number"
                value={filterConfig.refreshSeconds}
                onChange={(e) =>
                  onChange(
                    produce(filterConfig, (d) => {
                      d.refreshSeconds =
                        e.target.value < 2 ? 2 : e.target.value;
                    }),
                  )
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Component;
