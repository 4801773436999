import { useEffect } from "react";
import { useImmer } from "use-immer";
import ApiConnector from "../../connector/ApiConnector";
import moment from "moment/moment";

const Component = ({ currentConfigId, onIdSelected }) => {
  const [data, updateData] = useImmer([]);

  useEffect(async () => {
    updateData(
      await ApiConnector.getConfigHeaders(
        currentConfigId - 50,
        currentConfigId + 50,
        100,
      ),
    );
  }, [currentConfigId]);

  return (
    <>
      <select
        value={currentConfigId}
        onChange={(e) => onIdSelected(e.target.value)}
      >
        {data.map((header) => (
          <>
            <option value={header.id}>
              {header.id} | {moment(header.created).format("YYYY-MM-DD")} |{" "}
              {header.userId}
            </option>
          </>
        ))}
      </select>
    </>
  );
};

export default Component;
