const Component = {
  transactionStatuses: ["APPROVED", "DECLINED", "DECLINED_BLOCK_CARD"],
  transactionDetailStatuses: [
    "APPROVED_BY_RISK_EVALUATION",
    "DECLINED_BY_RISK_EVALUATION",
    "APPROVED_BY_PAN_SUPPRESS",
    "APPROVED_BY_KILL_SWITCH",
    "APPROVED_BY_CSV_IMPORT",
    "APPROVED_BY_MERCHANT_CODE_SUPPRESS",
    "APPROVED_BY_TERMINAL_ID_SUPPRESS",
    "DECLINED_BY_AUTOMATIC_REJECTION",
    "DECLINED_BLOCK_CARD_BY_RISK_EVALUATION",
    "DECLINED_BY_PROHIBITED_BLOCKING",
  ],
  riskEvaluationStatuses: ["APPROVED", "DECLINED", "DECLINED_BLOCK_CARD"],
};

export default Component;
