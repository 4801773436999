const Component = ({ values, selectedValues, onChange }) => {
  return (
    <>
      <span>
        {selectedValues.length < values.length && (
          <select
            value={""}
            onChange={(e) => {
              let newSelectedValues = [...selectedValues];
              if (selectedValues.includes(e.target.value)) {
                // do nothing
              } else {
                newSelectedValues = [...newSelectedValues, e.target.value];
              }
              onChange(newSelectedValues);
            }}
          >
            <option value={""}></option>
            {values
              .filter((v) => !selectedValues.includes(v))
              .map((value) => (
                <option value={value}>{value}</option>
              ))}
          </select>
        )}
        {selectedValues.map((selectedValue) => (
          <span>
            <a
              className="fa-solid fa-xmark"
              onClick={() =>
                onChange(selectedValues.filter((s) => s !== selectedValue))
              }
            ></a>
            &nbsp;
            {selectedValue}
            <br></br>
          </span>
        ))}
      </span>
    </>
  );
};

export default Component;
