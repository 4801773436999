import { useImmer } from "use-immer";
import ApiConnector from "../connector/ApiConnector";
import { useEffect, useRef } from "react";
import UtilService from "../service/UtilService";
import moment from "moment";
import GetFileViaS3 from "../component/S3FileAnchor";
import DisplayedAttributesSelectorReportPage from "../component/reportPage/DisplayedAttributesConfigReportPage";
import TransactionTableReportPage from "../component/reportPage/TransactionTableReportPage";
import FilteringParametersReportPage from "../component/reportPage/FilterConfigReportPage";

const Component = (props) => {
  const EXPORT_TO_CSV_DEFAULT_RANGE_HOURS = 3;
  const [data, updateData] = useImmer([]);
  const [displayedTransactionAttributes, updateDisplayedTransactionAttributes] =
    useImmer([]);
  const [filterConfig, updateFilterConfig] = useImmer({
    trnDateMax: "",
    trnDateMin: moment().subtract(7, "days").format("YYYY-MM-DDT00:00:00"),
    totalRiskMin: null,
    totalRiskMax: null,
    quantityMin: null,
    quantityMax: null,
    transactionTypes: ["A"],
    pans: [],
    merchantCodes: [],
    statuses: [],
    detailStatuses: [],
    riskEvaluationStatuses: [],
    rejectReasonCodes: [],
    transactionIds: [],
    isEstimatedFraud: false,
    isFraud: false,
    isHotspot: false,
    refreshSeconds: 10,
    pageSize: 30,
    pageNumber: 0,
  });
  const loadTransactionsBgJob = useRef(null);
  const [isLoadInProgress, updateIsLoadInProgress] = useImmer(false);
  const debounceFilterChangeTimeout = useRef(null);

  /**
   * Defines component unmount
   */
  useEffect(() => {
    return () => {
      console.log(
        "Clearing background job #: " + loadTransactionsBgJob.current,
      );
      clearInterval(loadTransactionsBgJob.current);
    };
  }, []);

  useEffect(() => {
    UtilService.debounce(
      () => {
        loadTransactions();
        startAutoRefreshBgJob();
      },
      200,
      debounceFilterChangeTimeout,
    );
  }, [filterConfig]);

  const startAutoRefreshBgJob = () => {
    const bgJob = setInterval(
      () => loadTransactions(),
      filterConfig.refreshSeconds * 1000,
    );
    clearInterval(loadTransactionsBgJob.current);
    loadTransactionsBgJob.current = bgJob;
  };

  const loadTransactions = async () => {
    console.log("Load transactions. ", filterConfig.pageNumber);
    while (isLoadInProgress) {
      await UtilService.sleep(1);
    }
    updateIsLoadInProgress(true);
    try {
      const transactions = await ApiConnector.getTransactions({
        pageSize: filterConfig.pageSize,
        offset: filterConfig.pageNumber * filterConfig.pageSize,
        trnDateMin: filterConfig.trnDateMin
          ? filterConfig.trnDateMin + "Z"
          : null,
        trnDateMax: filterConfig.trnDateMax
          ? filterConfig.trnDateMax + "Z"
          : null,
        totalRiskMin: filterConfig.totalRiskMin,
        totalRiskMax: filterConfig.totalRiskMax,
        transactionTypes:
          filterConfig.transactionTypes.length > 0
            ? filterConfig.transactionTypes
            : null,
        pans: filterConfig.pans.length > 0 ? filterConfig.pans : null,
        merchantCodes:
          filterConfig.merchantCodes.length > 0
            ? filterConfig.merchantCodes
            : null,
        statuses:
          filterConfig.statuses.length > 0 ? filterConfig.statuses : null,
        detailStatuses:
          filterConfig.detailStatuses.length > 0
            ? filterConfig.detailStatuses
            : null,
        riskEvaluationStatuses:
          filterConfig.riskEvaluationStatuses.length > 0
            ? filterConfig.riskEvaluationStatuses
            : null,
        rejectReasonCodes:
          filterConfig.rejectReasonCodes.length > 0
            ? filterConfig.rejectReasonCodes
            : null,
        transactionIds:
          filterConfig.transactionIds.length > 0
            ? filterConfig.transactionIds
            : null,
        isEstimatedFraud: filterConfig.isEstimatedFraud ? true : null,
        isFraud: filterConfig.isFraud ? true : null,
        isHotspot: filterConfig.isHotspot ? true : null,
      });
      updateData(transactions.data);
    } finally {
      updateIsLoadInProgress(false);
    }
  };

  const createCsv = () => {
    const trnDateMin = filterConfig.trnDateMin
      ? filterConfig.trnDateMin + "Z"
      : moment()
          .utcOffset(0)
          .subtract(EXPORT_TO_CSV_DEFAULT_RANGE_HOURS, "hours")
          .toISOString();
    const trnDateMax = filterConfig.trnDateMax
      ? filterConfig.trnDateMax + "Z"
      : moment().utcOffset(0).toISOString();

    const isMaxRangeBreached =
      moment(trnDateMax).valueOf() - moment(trnDateMin).valueOf() >
      1000 * 3600 * 24 * 40; // 40 days

    if (isMaxRangeBreached) {
      window.alert("Maximum range for CSV export can be 40 days.");
      return;
    }

    const presignedUrl = ApiConnector.generateRiskEvaluationCsvToS3(
      trnDateMin,
      trnDateMax,
    );

    return presignedUrl;
  };

  const setPageNumber = (value) => {
    updateFilterConfig((d) => {
      d.pageNumber = Number.parseInt(value);
      d.pageNumber = d.pageNumber > 500 ? 500 : d.pageNumber;
      d.pageNumber = d.pageNumber < 0 ? 0 : d.pageNumber;
    });
  };

  return (
    <>
      <header className="subheader">
        <h1>Report</h1>
      </header>
      <main>
        <div className="row">
          <div className="col">
            <h2>Transactions</h2>
          </div>
          <div className="col">
            <h2 className="float-right">
              <a
                className="fa-solid fa-angles-left"
                onClick={(e) => setPageNumber(filterConfig.pageNumber + 10)}
              />
              &nbsp;&nbsp;
              <a
                className="fa-solid fa-angle-left  "
                onClick={(e) => setPageNumber(filterConfig.pageNumber + 1)}
              />
              &nbsp;&nbsp;
              <a
                className={
                  "fa-solid fa-angle-right " +
                  (filterConfig.pageNumber === 0 ? "gray" : "")
                }
                onClick={(e) => setPageNumber(filterConfig.pageNumber - 1)}
              />
              &nbsp;&nbsp;
              <a
                className={
                  "fa-solid fa-angles-right " +
                  (filterConfig.pageNumber === 0 ? "gray" : "")
                }
                onClick={(e) => setPageNumber(filterConfig.pageNumber - 10)}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a
                className={
                  !isLoadInProgress
                    ? "fa-solid fa-arrows-rotate "
                    : "fas fa-sync fa-spin gray"
                }
                onClick={(e) => loadTransactions()}
              />
            </h2>
          </div>
        </div>

        <details>
          <summary>Filtering parameters</summary>
          <FilteringParametersReportPage
            filterConfig={filterConfig}
            onChange={(e) => updateFilterConfig(e)}
          ></FilteringParametersReportPage>
          <GetFileViaS3
            caption={
              "Export to CSV" +
              (filterConfig.trnDateMin
                ? ""
                : " (recent " + EXPORT_TO_CSV_DEFAULT_RANGE_HOURS + " hours)")
            }
            className="float-right"
            onFirstClick={() => createCsv()}
          ></GetFileViaS3>
          <details>
            <summary>Displayed columns</summary>
            For the current selection of transactions you can display following
            columns:
            <DisplayedAttributesSelectorReportPage
              sampleTransactions={data}
              onChange={(e) => updateDisplayedTransactionAttributes(e)}
            ></DisplayedAttributesSelectorReportPage>
          </details>
        </details>

        <TransactionTableReportPage
          transactions={data}
          displayedTransactionAttributes={displayedTransactionAttributes}
        ></TransactionTableReportPage>

        <p>Page number: {filterConfig.pageNumber}</p>
      </main>
    </>
  );
};

export default Component;
