import { useImmer } from "use-immer";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Component = ({ config, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Odometer classification</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>New Hot value</td>
            <td>
              <input
                type="number"
                value={data.newHotValueScore}
                onChange={(e) =>
                  updateData((d) => {
                    d.newHotValueScore = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>New Warm value</td>
            <td>
              <input
                type="number"
                value={data.newWarmValueScore}
                onChange={(e) =>
                  updateData((d) => {
                    d.newWarmValueScore = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Hot value</td>
            <td>
              <input
                type="number"
                value={data.hotValueScore}
                onChange={(e) =>
                  updateData((d) => {
                    d.hotValueScore = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Warm value</td>
            <td>
              <input
                type="number"
                value={data.warmValueScore}
                onChange={(e) =>
                  updateData((d) => {
                    d.warmValueScore = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Similarity min.</td>
            <td>
              <input
                  type="number"
                  value={data.similarityMin}
                  onChange={(e) =>
                      updateData((d) => {
                        d.similarityMin = e.target.value;
                      })
                  }
              />
            </td>
          </tr>
        </tbody>
      </table>
      <Link to="/config/odometer">View detail</Link>
    </>
  );
};

export default Component;
